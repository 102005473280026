














































import Vue from "vue";
import { Getter } from "vuex-class";
import { Article } from "@/core/models";
import { Component } from "vue-property-decorator";

@Component
export default class NewsCard extends Vue {
  @Getter("news/loading") loading!: boolean;
  @Getter("news/getLatestArticles") articles!: Article[];

  body(article: Article) {
    const text = article.Body;
    if (text.length < 100) return text;
    const section = text.substr(0, 100);
    return `${section}...`;
  }

  goToArticle(article: Article) {
    this.$router.push("/news/article/" + article.ID);
  }
}
