import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VCardTitle,{staticClass:"headline font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.$t("news.news")))]),_c(VSpacer),_c(VBtn,{attrs:{"link":"","text":"","to":"/news"}},[_c('span',[_vm._v(_vm._s(_vm.$t("news.all")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1),(!_vm.loading)?_c(VCardText,[(_vm.articles.length > 0)?_c(VRow,_vm._l((_vm.articles),function(article){return _c(VCol,{key:article.ID,staticClass:"mt-4 px-4",attrs:{"md":"3","cols":"12"}},[_c('div',{staticClass:"main--text headline font-weight-bold text-truncate"},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(article.Title))])]),_c('div',{staticClass:"main--text",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("news.posted"))+" "+_vm._s(_vm._f("format")(article.PostedAt))+" ")]),_c('div',[_vm._v(_vm._s(_vm.body(article)))]),_c('div',{staticClass:"d-flex mt-4"},[_c(VBtn,{staticStyle:{"border-color":"var(--v-accent-base)"},attrs:{"outlined":"","elevation":"0","color":"accentText"},on:{"click":function($event){return _vm.goToArticle(article)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("news.read")))])])],1)])}),1):_c('div',[_vm._v(_vm._s(_vm.$t("dashboardPage.newsCard.noNews")))])],1):_c(VCardText,[_vm._v(_vm._s(_vm.$t("global.loading")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }